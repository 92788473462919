import React from 'react'
import PropTypes from 'prop-types'

import Img from '../Image'
import Typography from '../Typography'
import Placeholder from '../Placeholder'
import * as Styled from './SplitContent.styles'

const SplitContent = ({
  alignImageToBottom,
  reverseForMobile,
  hideImageOnMobile,
  image,
  heading,
  subheading,
  mainContent,
  ...props
}) => {
  const imageWidth =
    image && image.childImageSharp && image.childImageSharp.fluid
      ? image.childImageSharp.fluid.presentationWidth
      : '100%'

  // TODO: [LC-314] remove className from root element and use data-attribute
  return (
    <Styled.Section
      hideImageOnMobile={hideImageOnMobile}
      alignImageToBottom={alignImageToBottom}
      imageWidth={imageWidth}
      reverseForMobile={reverseForMobile}
      {...props}
    >
      <div className="split-content__grid">
        <div className="split-content__image">
          {image ? (
            <Img
              data={image}
              style={{
                maxWidth: imageWidth,
                margin: '0 auto',
              }}
            />
          ) : (
            <Placeholder type="error">
              <Typography variant="small">Notice</Typography>
              <Typography>
                The content entry of type &quot;SplitContent&quot; is missing
                the Image media asset.
              </Typography>
            </Placeholder>
          )}
        </div>
        <div className="split-content__content">
          <Typography variant="h2">{heading}</Typography>
          {subheading && (
            <Typography variant="h4" rendersAs="h3">
              {subheading}
            </Typography>
          )}
          {mainContent}
        </div>
      </div>
    </Styled.Section>
  )
}

SplitContent.defaultProps = {
  alignImageToBottom: false,
  hideImageOnMobile: false,
  reverseForMobile: false,
  subheading: null,
  mainContent: null,
}

SplitContent.propTypes = {
  alignImageToBottom: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object], [PropTypes.string]).isRequired,
  hideImageOnMobile: PropTypes.bool,
  reverseForMobile: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  mainContent: PropTypes.node,
}

export default SplitContent
