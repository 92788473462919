import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Slider from 'react-slick'

// TODO: [LC-441] Replace Slick. Their font-family does not work well due to needing to load styles in via Helmet.

const Carousel = ({ children, ...props }) => (
  <>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Helmet>
    <Slider {...props}>{children}</Slider>
  </>
)

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Carousel
