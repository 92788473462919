import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Section from '../Section'

export const CustomSection = styled(Section)(
  ({ darkText, theme }) => css`
    position: relative;
    z-index: 0;
    color: ${darkText};
    background: #062f6e;

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 2em .5em;
    }
  `
);

export const Heading = styled.div(
  ({ theme, centeredContent, hasChildren }) => css`
  text-align: ${centeredContent};

  h2{
    background: #74bc1f;
    font-weight: 300;
    color: ${theme.colors.light.base.hex};
    margin: 0;
    text-align: center;
    padding: .2em;
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    h2{
      font-size: 1.1em;
    }
  }
`
)

export const Intro = styled.header(
  ({ theme, centeredContent, hasChildren }) => css`
    text-align: ${centeredContent};
    
    @media (min-width: ${theme.breakpoints.lg}) {
      margin-left: auto;
      margin-right: auto;
    }
  `
)

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ centeredContent }) =>
    centeredContent ? 'center' : 'flex-start'};
`