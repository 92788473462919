import React from 'react'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'

import Section from '../components/Section'
import CustomContent from '../components/CustomContent'
import Carousel from '../components/Carousel'
import Pullquote from '../components/Pullquote'
import VariableContent from '../components/VariableContent'
import SplitContent from '../components/SplitContent'
import Billboard from '../components/Billboard'
import PackageSection from '../components/PackageSection'
import _get from 'lodash.get'
import richTextBlockMap from './richTextBlockMap'
import determineModule from './determineModule'
import determineContentAmount from './determineContentAmount'

// TODO: remove this when 'Single Package' has proper support
const tempPackage = {
  title: 'DIRECTV® Ultimate All-Included + INTERNET + HOME PHONE',
  details: ['300 Channels', '30Mbps Internet', 'Home Phone'],
  price: {
    amount: 79.99,
  },
  legal:
    'For 12 months with 24-mo. DIRECTV agreement and combined billing. Price includes DIRECTV ULTIMATE All- Included Package ($60.00/mo.)',
  termsLink: 'Terms and Conditions',
}

export default section => {
  if (section.__typename === 'ContentfulCarouselSection') {
    const { slides } = section

    return (
      <Section>
        <Carousel
          dots
          infinite={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={0}
        >
          {slides.map(slide => (
            <Pullquote key={slide.id} cite={slide.attribution}>
              {slide.quote.quote}
            </Pullquote>
          ))}
        </Carousel>
      </Section>
    )
  }

  const {
    id,
    type,
    heading,
    subheading,
    mainContent,
    featuredImage,
    fullWidthFeaturedImage,
    customFeaturedImage,
    prominent,
    alignImageToBottom,
    backgroundColor,
    modules,
  } = section
  let Component
  const props = { prominent }

  switch (type) {
    case 'Split Content':
      Component = SplitContent
      props.image = {
        childImageSharp: _get(featuredImage, 'localFile.childImageSharp'),
        description: _get(featuredImage, 'description'),
        fields: _get(featuredImage, 'localFile.fields'),
      }
      props.alignImageToBottom = alignImageToBottom
      break
    case 'Billboard':
      Component = Billboard
      props.image = {
        childImageSharp: _get(
          fullWidthFeaturedImage,
          'localFile.childImageSharp'
        ),
        description: _get(fullWidthFeaturedImage, 'description'),
        fields: _get(fullWidthFeaturedImage, 'localFile.fields'),
      }
      props.variant = 'full'
      break
    case 'Custom':
      Component = CustomContent
      props.image = {
        childImageSharp: _get(
          customFeaturedImage,
          'localFile.childImageSharp'
        ),
        description: _get(customFeaturedImage, 'description'),
        fields: _get(customFeaturedImage, 'localFile.fields'),
      }
      break
    case 'Split Billboard':
      Component = Billboard
      props.image = {
        childImageSharp: _get(
          fullWidthFeaturedImage,
          'localFile.childImageSharp'
        ),
        description: _get(fullWidthFeaturedImage, 'description'),
        fields: _get(fullWidthFeaturedImage, 'localFile.fields'),
      }
      props.backgroundColor = backgroundColor
      break
    case 'Single Package':
      Component = PackageSection
      props.package = tempPackage
      break
    default:
      props.alignImageToBottom = alignImageToBottom
      props.image = {
        childImageSharp: _get(featuredImage, 'localFile.childImageSharp'),
        description: _get(featuredImage, 'description'),
        fields: _get(featuredImage, 'localFile.fields'),
      }
      Component = VariableContent
  }

  return (
    <Component
      key={id}
      heading={heading}
      subheading={subheading}
      backgroundColor={backgroundColor}
      mainContent={
        mainContent && mapRichText(mainContent.json, richTextBlockMap)
      }
      centeredContent={
        mainContent && !determineContentAmount(mainContent.json, 3, 50)
      }
      {...props}
    >
      {modules && modules.map(module => determineModule(module))}
    </Component>
  )
}
