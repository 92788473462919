export default (json, paragraphAmount = 2, paragraphLength = 20) => {
  const content = json.content.filter(
    obj => obj.nodeType === 'paragraph' && obj.content[0].value
  )

  return (
    content.length >= paragraphAmount ||
    (content.length &&
      content
        .reduce((acc, curr) => `${acc} ${curr.content[0].value}`, '')
        .split(' ').length >= paragraphLength)
  )
}
