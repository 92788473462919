import styled from '@emotion/styled'
import { stripUnit } from 'polished'

export const Animator = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xs};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  height: ${({ theme }) => `calc(100% - ${stripUnit(theme.spacing.xs) * 2}px)`};

  > * {
    transition: all 300ms ease;
  }

  &:hover {
    cursor: pointer;

    > * {
      transform: translateY(-8px);
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary.base.hex},
        0 8px 10px ${({ theme }) => theme.colors.light.darker.hex};
      border-color: ${({ theme }) => theme.colors.primary.dark.hex};

      button {
        background-color: ${({ theme }) => theme.colors.primary.dark.hex};
      }
    }
  }
`

export const PackageCard = styled.div`
  box-shadow: 0 4px 8px ${({ theme }) => theme.colors.light.darker.hex};
  border-top: ${({ theme }) =>
    `solid ${theme.spacing.xxs} ${theme.colors.primary.base.hex}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 320px;
  text-align: center;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.radius};

  button {
    align-self: stretch;
    margin: ${({ theme }) => `${theme.spacing.lg} 0 0`};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .label {
    transform: ${({ theme }) =>
      `translateY(calc(-100% - ${theme.spacing.xxs}))`};
    padding: ${({ theme }) => `${theme.spacing.min} ${theme.spacing.lg}`};
    background-color: ${({ theme }) => theme.colors.primary.dark.hex};
    position: absolute;
    margin: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
  }

  .title {
    margin: ${({ theme }) => `${theme.spacing.xxs} 0 ${theme.spacing.sm}`};
    padding: ${({ theme }) => `0 ${theme.spacing.lg} 0`};
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .details {
    margin: ${({ theme }) => `0 0 ${theme.spacing.sm}`};
    padding: ${({ theme }) => `${theme.spacing.sm} 0`};
    background-color: ${({ theme }) => theme.colors.light.dark.hex};
    display: flex;
    justify-content: space-around;
    align-self: stretch;
    font-size: 12px;
    line-height: 14px;

    > span {
      border-right: 1px solid ${({ theme }) => theme.colors.light.darker.hex};
      flex: 1;
      padding: ${({ theme }) => `${theme.spacing.min}  ${theme.spacing.xs}`};

      &:last-of-type {
        border-right: none;
      }
    }
  }

  .terms {
    padding: ${({ theme }) => `0 ${theme.spacing.lg} 0`};
    font-size: 10px;
    font-weight: bold;
    text-decoration: none;
    color: #444;
  }

  .bullets {
    display: none;
  }

  .spacer {
    flex: 1;
  }

  .price {
    margin-bottom: ${({ theme }) => theme.spacing.sm};

    &__wrapper {
      display: inline-block;
    }
  }
`
