import React from 'react'
import { documentToReactComponents as mapRichText } from '@contentful/rich-text-react-renderer'
import Columns from '../components/Columns'
import Column from '../components/Columns/Column'
import Carousel from '../components/Carousel'
import Pullquote from '../components/Pullquote'
import Typography from '../components/Typography'
import Placeholder from '../components/Placeholder'
import Table from '../components/Table'
import _get from 'lodash.get'
import richTextBlockMap, { richTextLegalMap } from './richTextBlockMap'
import determineContentAmount from './determineContentAmount'

export default module => {
  const { __typename: typename } = module

  switch (typename) {
    case 'ContentfulCollection':
      if (module.type === 'Columns') {
        return (
          <Columns key={module.id} cardStyled={module.cardStyled}>
            {module.items.map(item => (
              <Column
                key={item.id}
                image={{
                  childImageSharp: _get(
                    item,
                    'image.localFile.childImageSharp'
                  ),
                  description: _get(item, 'image.description'),
                  fields: _get(item, 'image.localFile.fields'),
                }}
                centeredContent={
                  item.mainContent &&
                  determineContentAmount(item.mainContent.json)
                }
              >
                {item.heading && (
                  <Typography variant="h4">{item.heading}</Typography>
                )}
                {item.subheading && (
                  <Typography variant="h5">{item.subheading}</Typography>
                )}
                {item.mainContent &&
                  mapRichText(item.mainContent.json, richTextBlockMap)}
              </Column>
            ))}
          </Columns>
        )
      }

      if (module.type === 'Stacked') {
        return module.items.map(item => (
          <div key={item.id}>
            {item.heading && (
              <Typography variant="h4">{item.heading}</Typography>
            )}
            {item.subheading && (
              <Typography variant="h5">{item.subheading}</Typography>
            )}
            {item.mainContent &&
              mapRichText(item.mainContent.json, richTextBlockMap)}
          </div>
        ))
      }

      // TODO: Conditionally returning Pullquote or Column should be re-thought
      // This is a temporary patch and there is likely a way to standardize the logic better
      if (module.type === 'Carousel') {
        return (
          <Carousel
            dots
            infinite={false}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            initialSlide={0}
          >
            {module.items.map(item => {
              if (item.__typename === 'ContentfulPullquote') {
                return (
                  <Pullquote cite={item.attribution}>
                    {item.quote.quote}
                  </Pullquote>
                )
              }

              return (
                <Column
                  key={item.id}
                  image={{
                    childImageSharp: _get(
                      item,
                      'image.localFile.childImageSharp'
                    ),
                    description: _get(item, 'image.description'),
                    fields: _get(item, 'image.localFile.fields'),
                  }}
                  hasLargeContent={
                    item.mainContent &&
                    determineContentAmount(item.mainContent.json)
                  }
                >
                  {item.heading && (
                    <Typography variant="h4">{item.heading}</Typography>
                  )}
                  {item.subheading && (
                    <Typography variant="h5">{item.subheading}</Typography>
                  )}
                  {item.mainContent &&
                    mapRichText(item.mainContent.json, richTextBlockMap)}
                </Column>
              )
            })}
          </Carousel>
        )
      }

      return (
        <Placeholder type="warning">
          <Typography variant="small">Missing Component</Typography>
          <Typography>
            The {typename} component can&apos;t be rendered here. It is most
            likely missing support from the core.
          </Typography>
        </Placeholder>
      )
    case 'ContentfulDisclaimer':
      return mapRichText(module.mainContent.json, richTextLegalMap)
    case 'ContentfulBasicTable':
      return <Table json={module.tableData} />
    default:
      return (
        <Placeholder type="warning">
          <Typography variant="small">Missing Component</Typography>
          <Typography>
            The {typename} component can&apos;t be rendered here. It is most
            likely missing support from the core.
          </Typography>
        </Placeholder>
      )
  }
}
