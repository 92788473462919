import { css } from '@emotion/core'
import { stripUnit } from 'polished'

import styled from '@emotion/styled'
import Img from '../Image'
import SectionComponent from '../Section'
import fluidRange from '../../utils/fluidRange'

export const Section = styled(SectionComponent)(
  ({ theme }) => css`
    position: relative;
  `
)

export const Content = styled.div(({ theme, variant, prominent, backgroundColor }) => {
  let bgColor = 'transparent'

  if (variant === 'full') {
    if (prominent) {
      bgColor = theme.colors[theme.prominent].base.hex
    } else {
      bgColor = theme.colors.light.lighter.hex
    }
  }

  let fontColor = 'transparent'

  if (backgroundColor) {
    fontColor = theme.colors.light.base.hex
  } else {
    fontColor = theme.colors.dark.base.hex
  }


  return css`
    position: relative;
    z-index: 1;
    ${prominent && `color: ${theme.colors[theme.prominent].base.readable}`};
    color:  ${fontColor};

    @media (min-width: ${theme.breakpoints.md}) {
      width: 50%;
      box-shadow: ${variant === 'full' ? theme.shadow : 'none'};
      ${variant === 'full' &&
    fluidRange('padding', [
      stripUnit(theme.spacing.sm),
      stripUnit(theme.spacing.lg),
    ])};
      ${variant === 'split' &&
    fluidRange('padding-right', [
      stripUnit(theme.spacing.lg),
      stripUnit(theme.spacing.xxl),
    ])};

      background-color: ${bgColor};
    }

    section:nth-of-type(even) & {
      @media (min-width: ${theme.breakpoints.md}) {
        left: 50%;
        ${variant === 'split' &&
    `${fluidRange('padding-left', [
      stripUnit(theme.spacing.lg),
      stripUnit(theme.spacing.xxl),
    ])};
            padding-right: 0;
          `}
      }
    }

    
  `
})

export const Image = styled(Img)(
  ({ theme, variant }) => css`
    
    @media (min-width: ${theme.breakpoints.md}) {
      display: block;
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: ${variant === 'full' ? '0' : '50%'};
    }

    section:nth-of-type(even) & {
      @media (min-width: ${theme.breakpoints.md}) {
        transform: scale(-1, 1);
        right: ${variant === 'full' ? '0' : '50%'};
        left: 0;
      }
    }
  `
)