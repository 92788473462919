import React from 'react'
import PropTypes from 'prop-types'

import Columns from '../Columns'
import Column from '../Columns/Column'
import PackageCard from '../PackageCard'
import Typography from '../Typography'
import * as Styled from './PackageSection.styles'

const PackageSection = ({
  package: packageData,
  heading,
  subheading,
  mainContent,
  // eslint-disable-next-line no-unused-vars
  children,
}) => (
  <Styled.Section>
    <Columns className="package-section__grid" centeredContent={false}>
      <Column className="package-section__content">
        <Typography variant="h2">{heading}</Typography>
        {subheading && (
          <Typography variant="h4" rendersAs="h3">
            {subheading}
          </Typography>
        )}
        {mainContent}
      </Column>
      <PackageCard
        className="package-section__card"
        packageData={packageData}
      />
    </Columns>
  </Styled.Section>
)

PackageSection.defaultProps = {
  subheading: null,
  mainContent: null,
  children: null,
}

PackageSection.propTypes = {
  package: PropTypes.shape({
    title: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.string),
    bullets: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.shape({ amount: PropTypes.number }),
    termsLink: PropTypes.string,
    legal: PropTypes.string,
  }).isRequired,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  mainContent: PropTypes.node,
  children: PropTypes.element,
}

export default PackageSection
