import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import * as Styled from './Billoard.styles'
import Placeholder from '../Placeholder'

const Billboard = ({
  image,
  heading,
  subheading,
  mainContent,
  prominent,
  variant,
  backgroundColor,
  ...props
}) => (
    <Styled.Section prominent={prominent} {...props} style={{ background: !backgroundColor ? "#FFF" : "#" + backgroundColor }}>
      <Styled.Content prominent={prominent} variant={variant} backgroundColor={backgroundColor}>
        <Typography variant="h2">{heading}</Typography>
        {subheading && (
          <Typography variant="h4" rendersAs="h3">
            {subheading}
          </Typography>
        )}
        {mainContent}
      </Styled.Content>
      {image && <Styled.Image data={image} variant={variant} />}
      {!image && (
        <Placeholder type="error">
          <Typography variant="small">Notice</Typography>
          <Typography>
            The content entry of type &quot;Billboard&quot; is missing the Image
            media asset.
        </Typography>
        </Placeholder>
      )}
    </Styled.Section>
  )

Billboard.defaultProps = {
  variant: 'split',
  prominent: false,
  subheading: null,
  mainContent: null,
  backgroundColor: null,
}

Billboard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object], [PropTypes.string]).isRequired,
  variant: PropTypes.oneOf(['split', 'full']),
  prominent: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  mainContent: PropTypes.node,
  backgroundColor: PropTypes.string,
}

export default Billboard