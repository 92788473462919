import styled from '@emotion/styled'

import SectionComponent from '../Section'

export const Section = styled(SectionComponent)`
  &:nth-of-type(even) {
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      .package-section__grid {
        grid-template-areas: 'card content content';
      }
    }
  }

  .package-section {
    &__grid {
      display: grid;
      grid-gap: ${({ theme }) => theme.spacing.xl};
      grid-template-rows: auto auto;
      grid-template-areas:
        'card'
        'content';

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
        grid-template-rows: none;
        grid-template-areas: 'content content card';
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__card {
      grid-area: card;
    }

    &__content {
      grid-area: content;
    }
  }
`
