import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './CustomContent.styles'
import Img from '../Image'
import Typography from '../Typography'

const CustomContent = ({
  heading,
  mainContent,
  image,
  children,
  centeredContent,
  childImageSharp,
  ...props
}) => {

  return (
    <>
      <Styled.Heading centeredContent={centeredContent}>
        <Typography variant="h2">{heading}</Typography>
      </Styled.Heading>

      <Styled.CustomSection {...props} style={{ display: !image.childImageSharp ? "none" : "block" }}>
        <Styled.Intro centeredContent={centeredContent}>
          <Img data={image} />
          <Styled.Content centeredContent={centeredContent}>
            {mainContent}
          </Styled.Content>
        </Styled.Intro>
      </Styled.CustomSection>
    </>
  )
}

CustomContent.defaultProps = {
  subheading: '',
  mainContent: null,
  children: null,
  image: null,
  centeredContent: false,
}

CustomContent.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }),
  centeredContent: PropTypes.bool,
  mainContent: PropTypes.shape({ json: PropTypes.object }),
  children: PropTypes.node,
}

export default CustomContent