import React from 'react'
import PropTypes from 'prop-types'

import Typography from '../Typography'
import Button from '../Button'
import PackageProvider from '../PackageProvider'
import * as Styled from './PackageCard.styles'

const PackageCard = ({ label, packageData, cta, ...props }) => (
  <Styled.Animator {...props}>
    <Styled.PackageCard>
      {label && (
        <Typography variant="small" className="label">
          {label}
        </Typography>
      )}
      <PackageProvider {...packageData} />
      <div className="spacer" />
      <Button variant="feature">{cta}</Button>
    </Styled.PackageCard>
  </Styled.Animator>
)

PackageCard.defaultProps = {
  label: '',
  cta: 'Learn More',
}

PackageCard.propTypes = {
  label: PropTypes.string,
  packageData: PropTypes.shape({
    title: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.string),
    bullets: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.shape({ amount: PropTypes.number.isRequired }),
    termsLink: PropTypes.string,
    legal: PropTypes.string,
  }).isRequired,
  cta: PropTypes.string,
}

export default PackageCard
